<template>
	<BaseLoading v-if="installment.edit.isLoading" is-full-page />
	<FormInstallmentPlan
		v-else
		:agent="data.paymentMethod.agent"
		:interest="data.interestRate"
		:period="data.period"
		:min-order-amount="data.minOrderAmount"
		:max-order-amount="data.maxOrderAmount"
		:is-submitting="installment.edit.isUpdating"
		:group-id="$route.params.id"
		:method-id="$route.params.methodId"
		:gateway="data.paymentMethod.gateway"
		:custom-attributes="data.customAttributes"
		is-edit
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import FormInstallmentPlan from '@/components/FormInstallmentPlan.vue';

export default {
	name: 'InstallmentPlanEdit',

	components: {
		FormInstallmentPlan,
	},
	computed: {
		...mapState('payments', ['installment']),

		...mapGetters({
			data: 'payments/installmentDetails',
		}),
	},
	async created() {
		await this.getPlan({
			methodId: this.$route.params.methodId,
			planId: this.$route.params.planId,
		});

		// Set breadcrumb
		const bankName = this.data.paymentMethod.name;
		this.setBreadcrumbs([
			{
				title: 'Payment settings',
				route: { name: 'PaymentList' },
			},
			{
				title: `Installment credit card / ${bankName}`,
				route: {
					name: 'PaymentMethodSetting',
					params: { id: this.$route.params.id, methodId: this.$route.params.methodId },
				},
			},
		]);
	},
	methods: {
		...mapActions({
			getPlan: 'payments/getInstallmentPlan',
			updatePlan: 'payments/updateInstallmentPlan',
			setBreadcrumbs: 'breadcrumb/setBreadcrumbs',
		}),

		async handleSubmit(params) {
			const methodId = this.$route.params.methodId;
			const planId = this.$route.params.planId;

			await this.updatePlan({
				methodId,
				planId,
				params,
			});
		},
	},
};
</script>
